import Page from "../components/page";
import PersonCard from "../components/person-card";
import PropTypes from "prop-types";
import React from "react";
import SEO from "../components/seo";
import { graphql } from "gatsby";

const TeamPage = ({ data }) => {
  let delay = 0;
  const teamCards = data.employees.edges.map(employee => {
    delay += 50;
    return (
      <div
        key={employee.node.id}
        className='mb-10 sm:mb-0 sm:px-2 md:px-3 sm:py-3 lg:py-6 sm:w-1/2 lg:w-1/3 xl:w-1/4'
        data-aos='fade-up'
        data-aos-delay={delay}
      >
        <PersonCard
          email={employee.node.email}
          firstName={employee.node.firstName}
          image={employee.node.profileImage.fluid.src}
          jobTitle={employee.node.jobTitle}
          lastName={employee.node.lastName}
          path={`/team/${employee.node.firstName}-${employee.node.lastName}`
            .toLowerCase()
            .replace(/ /g, "-")}
          phone={employee.node.phone}
        />
      </div>
    );
  });

  return (
    <Page>
      <SEO title='Team' />
      <div className='container flex items-center px-8 md:pt-10 lg:pt-16 lg:pb-12'>
        <div className='hidden md:block w-1/3'>
          <svg className='max-w-xs w-full' viewBox='0 0 38 40'>
            <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
              <g transform='translate(0.590000, 0.430000)' fillRule='nonzero'>
                <circle
                  id='Oval'
                  fill='#B0BECA'
                  cx='10.14'
                  cy='29.25'
                  r='10.14'
                />
                <circle
                  id='Oval'
                  fill='#3C6E8D'
                  cx='31.71'
                  cy='21.4'
                  r='5.66'
                />
                <circle
                  id='Oval'
                  fill='#D0E0D9'
                  cx='18.69'
                  cy='7.98'
                  r='7.98'
                />
              </g>
            </g>
          </svg>
        </div>
        <div className='md:pl-16 max-w-2xl w-full md:w-2/3'>
          <h1 className='title mb-4'>Teamleden</h1>
          <p className='mb-4 md:text-justify'>
            Alle medewerkers hebben een medisch, psychologische of
            menswetenschappelijke opleiding desgevallend aangevuld met een
            erkende psychotherapieopleiding.
          </p>
          <p className='mb-4 md:text-justify'>
            U vindt alle informatie en contactgegevens per teamlid. Als u
            twijfelt bij wie u best terecht kan, kan u contact opnemen met
            Katlijn Willems, praktijkcoördinator, via&nbsp;
            <a
              className='text-secondary hover:underline'
              href={`mailto:${data.site.siteMetadata.email}`}
            >
              {data.site.siteMetadata.email}
            </a>{" "}
            of&nbsp;
            <a
              className='text-secondary hover:underline'
              href={`mailto:${data.site.siteMetadata.phone}`}
            >
              {data.site.siteMetadata.phone}
            </a>
            .
          </p>
          <p className='mb-4 md:text-justify'>
            Omdat we onze volledige aandacht willen geven tijdens de
            begeleiding, staat de telefoon af tijdens de gesprekken. Er is dus
            veel kans dat u op een antwoordapparaat terecht komt. Spreek dan
            zeker uw naam en telefoonnummer in, we bellen u dan terug.
          </p>
        </div>
      </div>
      <div className='p-8 mb-8 lg:mb-20'>
        <div className='container sm:flex sm:flex-wrap'>{teamCards}</div>
      </div>
    </Page>
  );
};

TeamPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default TeamPage;

export const pageQuery = graphql`
  query {
    employees: allContentfulEmployee(
      sort: { fields: [createdAt], order: ASC }
    ) {
      edges {
        node {
          createdAt
          id
          firstName
          lastName
          email
          phone
          jobTitle
          profileImage {
            fluid {
              src
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        email
        phone
      }
    }
  }
`;
